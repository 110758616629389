// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  /*apiUrl: 'https://tradeanalytix.com/service',
  redirectUri: 'http://localhost:4200/oauth-redirect',*/
  /*apiUrl: 'https://tradeanalytix.com/service',
  redirectUri: 'https://tradeanalytix.com/oauth-redirect',*/
  /*apiUrl: 'https://tradeanalytix.com/service/us',
  redirectUri: 'https://us.tradeanalytix.com/oauth-redirect',*/
  apiUrl: 'https://us.cuetrade.io/service',
  redirectUri: 'https://us.cuetrade.io/oauth-redirect',
  /*apiUrl: 'http://localhost:8080/service',
  redirectUri: 'http://localhost:4200/oauth-redirect',*/
  tz: 'Asia/Calcutta'
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
