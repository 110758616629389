export class ChartRequest {

    id: string;

    fromDate: string;

    toDate: string;

    symbol: string;
   
    stockId: number;
    
}
