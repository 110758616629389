import { Injectable } from '@angular/core';
import { SwUpdate } from '@angular/service-worker';
@Injectable()
export class PwaService {
  
  promptEvent:any;
  
  constructor(private swUpdate: SwUpdate) {

    window.addEventListener('beforeinstallprompt', event => {
      this.promptEvent = event;
    });

    this.swUpdate.available.subscribe(event => {
      if (confirm('A new version of the app is available, Would you like to update?')) {
        window.location.reload();
      }
    });
  }
}